<template>
  <div class="wait-screen">
      <b-container>
      <h4>Please check your email for an easy-to-click link to complete your login.</h4>
            <h4>It should be there now.</h4>
  </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>